import React, { useState } from "react";
import face from "@/assets/images/Face.jpeg";
import Image from "next/image";
import { useUserLocation } from "@/context/UserLocationContext";

const WhatsAppChat = () => {
  const userLocation = useUserLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [questions] = useState([
    "What's your name?",
    "What's your email?",
    "What's your phone?",
    "Any additional comments?",
  ]);
  const [userResponse, setUserResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleChatBox = () => {
    setIsOpen(!isOpen);
    resetChat();
  };

  const countryCode = userLocation?.country_calling_code || ""; // Get the country calling code if available
  const [editedCountryCode, setEditedCountryCode] = useState(countryCode); // Editable country code

  const resetChat = () => {
    setStep(0);
    setUserName("");
    setUserEmail("");
    setUserPhone("");
    setUserResponse("");
    setShowCloseConfirmation(false);
    setErrorMessage(""); // Reset error message
  };

  const validateInput = () => {
    // Clear previous error messages
    setErrorMessage("");

    // Validate user responses based on the current step
    if (step === 1) {
      // Username validation
      if (userResponse.trim() === "") {
        setErrorMessage("Required.");
        return false;
      }
      // You can add more username validation criteria here if needed (e.g., min/max length)
      setUserName(userResponse);
    } else if (step === 2) {
      // Email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(userResponse)) {
        setErrorMessage("Please enter a valid email address.");
        return false;
      }
      setUserEmail(userResponse);
    } else if (step === 3) {
      // Phone number validation (only digits and not empty)
      const phonePattern = /^[0-9]*$/;

      // Check if the input is empty
      // if (userResponse.trim() === "") {
      //   setErrorMessage("Required.");
      //   return false;
      // }

      // Check if the input matches the pattern (only digits)
      if (!phonePattern.test(userResponse)) {
        setErrorMessage("Please enter a valid phone number.");
        return false;
      }

      // setUserPhone(userResponse);
      const countryCode = userLocation?.country_calling_code || ""; // Use country code if available
      const finalCountryCode = editedCountryCode || countryCode; // Use edited or default country code

      // Set the phone number with the detected or default country code
      setUserPhone(`${finalCountryCode}${userResponse}`);
    }

    return true; // Input is valid
  };

  const handleSendMessage = () => {
    if (validateInput()) {
      if (step < questions.length) {
        if (step === 0) {
          setUserName(userResponse);
        }
        setUserResponse("");
        setStep(step + 1);
      }
    }
  };

  const handleConnectWhatsApp = () => {
    window.open(
      "https://wa.me/919353509364?text=Hi!%20I'm%20interested%20in%20your%20services",
      "_blank"
    );
  };
  const onSubmit = async () => {
    setIsLoading(true);
    try {
      // Get the current page URL
      const currentPageUrl = window.location.href;
      // The phone number and message
      const wappUrl = `https://wa.me/${userPhone}?text=${encodeURIComponent(
        "Hi! I'm interested in your services"
      )}`;
      // Open the WhatsApp chat with the predefined message
      // window.open(wappUrl, '_blank');
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_MAIN_API_URL}/user/contact-us`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: userPhone,
            message: `***Chat bot*** ${currentPageUrl} *** ${wappUrl} *** ${userResponse} `,
            email: userEmail,
            name: userName,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);
      resetChat();
      toggleChatBox();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseClick = () => {
    if (showCloseConfirmation) {
      setShowCloseConfirmation(false);
      toggleChatBox();
    } else {
      setShowCloseConfirmation(true);
    }
  };

  return (
    <div className="flex flex-col fixed bottom-4 right-4 z-50">
      {isOpen && (
        <div className="fixed bottom-36 right-4 w-[350px] bg-white shadow-xl rounded-lg z-50">
          <div className="bg-gradient h-64 rounded-t-lg absolute w-full z-0"></div>
          <div className="flex flex-col overflow-y-auto h-full p-4 space-y-4">
            <div className="flex flex-col z-10 ml-4 text-white">
              <Image
                src={face}
                alt="WhatsApp Chat"
                className="w-16 h-16 rounded-full border-2 border-white"
                width={50}
                height={50}
              />
              <button
                className="absolute top-2 right-2 text-white "
                onClick={handleCloseClick}
                title="Close Chat"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 10.586l4.95-4.95 1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05l1.414-1.414L12 10.586z"
                    clipRule="evenodd"
                  />
                </svg>{" "}
              </button>
              <div className="text-3xl mb-2">Hi Sophie 👋</div>
              <div className="w-60 text-gray-200 text-sm mb-1">
                We help your business grow by connecting you to your customers.
              </div>
            </div>
            <div className="border-0 border-t-4 border-blue-500 rounded z-10 shadow-md">
              <div className="bg-white border border-t-0 rounded-t-none rounded-b p-6 flex flex-col space-y-2 relative">
                {showCloseConfirmation ? (
                  <div className="flex flex-col space-y-2">
                    <div className="font-semibold text-sm mb-2">
                      Are you sure you want to leave the chat?
                    </div>
                    <div className="flex space-x-2">
                      <button
                        className="border border-[var(--blue-color)]  text-[#0070f0] rounded py-2 flex-1"
                        onClick={toggleChatBox}
                      >
                        Leave Chat
                      </button>
                      <button
                        className="bg-[#0070f0] text-white rounded py-2 flex-1"
                        onClick={() => setShowCloseConfirmation(false)}
                      >
                        Back to Chat
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {step > 0 && (
                      <button
                        className="absolute top-2 left-2 text-blue-700"
                        onClick={() => {
                          setStep(step - 1);
                          setUserResponse("");
                          setErrorMessage(""); // Clear error message on back
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          shapeRendering="geometricPrecision"
                          textRendering="geometricPrecision"
                          imageRendering="optimizeQuality"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          viewBox="0 0 512 404.43"
                          className="w-[14px] h-[14px]"
                        >
                          <path
                            fillRule="nonzero"
                            d="m68.69 184.48 443.31.55v34.98l-438.96-.54 173.67 159.15-23.6 25.79L0 199.94 218.6.02l23.6 25.79z"
                          />
                        </svg>
                      </button>
                    )}

                    {/* {step === 0 && (
                      <div className="font-semibold mb-2 text-sm">
                        Please select an option:
                      </div>
                    )} */}
                    {step === 0 && (
                      <div className="flex flex-col space-y-2">
                        <button
                          className="border border-[var(--blue-color)] text-[#0070f0] rounded py-1 flex items-center justify-center"
                          onClick={() => {
                            setStep(1);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_1"
                            data-name="Layer 1"
                            className="w-8 h-8 mr-2" // Add margin to the right of the SVG
                            viewBox="0 0 122.88 108.25"
                          >
                            <defs>
                              <style>{".cls-1{fill-rule:evenodd;}"}</style>
                            </defs>
                            <path
                              style={{ fill: "#0070f0" }}
                              className="cls-1"
                              d="M51.16,93.74c13,12.49,31.27,16.27,49.59,8.46l15.37,6L111,96.13c17.08-13.68,14-32.48,1.44-45.3a44.38,44.38,0,0,1-4.88,13.92A51.45,51.45,0,0,1,93.45,80.84a62.51,62.51,0,0,1-19.73,10,71.07,71.07,0,0,1-22.56,2.92ZM74.74,36.13a6.68,6.68,0,1,1-6.68,6.68,6.68,6.68,0,0,1,6.68-6.68Zm-44.15,0a6.68,6.68,0,1,1-6.68,6.68,6.68,6.68,0,0,1,6.68-6.68Zm22.08,0A6.68,6.68,0,1,1,46,42.81a6.68,6.68,0,0,1,6.68-6.68ZM54,0H54c14.42.44,27.35,5.56,36.6,13.49,9.41,8.07,15,19,14.7,31v0c-.36,12-6.66,22.61-16.55,30.11C79,82.05,65.8,86.4,51.38,86a64.68,64.68,0,0,1-11.67-1.4,61,61,0,0,1-10-3.07L7.15,90.37l7.54-17.92A43.85,43.85,0,0,1,4,59,36.2,36.2,0,0,1,0,41.46c.36-12,6.66-22.61,16.55-30.12C26.3,4,39.53-.4,54,0ZM53.86,5.2h0C40.59,4.82,28.52,8.77,19.69,15.46,11,22,5.5,31.28,5.19,41.6A31.2,31.2,0,0,0,8.61,56.67a39.31,39.31,0,0,0,10.81,13L21,70.87,16.68,81.05l13.08-5.14,1,.42a55.59,55.59,0,0,0,10.05,3.18A59,59,0,0,0,51.52,80.8c13.22.39,25.29-3.56,34.12-10.26C94.31,64,99.83,54.73,100.15,44.4v0c.3-10.32-4.65-19.85-12.9-26.92C78.85,10.26,67.06,5.6,53.87,5.2Z"
                            />
                          </svg>
                          Send us a message
                        </button>

                        <button
                          className="border border-[var(--blue-color)] text-[#0070f0] rounded py-1 flex items-center justify-center"
                          onClick={handleConnectWhatsApp}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="w-7 h-7 mr-2"
                            fill="currentColor"
                          >
                            <path
                              style={{ fill: "#EDEDED" }}
                              d="M0,512l35.31-128C12.359,344.276,0,300.138,0,254.234C0,114.759,114.759,0,255.117,0 S512,114.759,512,254.234S395.476,512,255.117,512c-44.138,0-86.51-14.124-124.469-35.31L0,512z"
                            />
                            <path
                              style={{ fill: "#55CD6C" }}
                              d="M137.71,430.786l7.945,4.414c32.662,20.303,70.621,32.662,110.345,32.662  c115.641,0,211.862-96.221,211.862-213.628S371.641,44.138,255.117,44.138S44.138,137.71,44.138,254.234  c0,40.607,11.476,80.331,32.662,113.876l5.297,7.945l-20.303,74.152L137.71,430.786z"
                            />
                            <path
                              style={{ fill: "#FEFEFE" }}
                              d="M187.145,135.945l-16.772-0.883c-5.297,0-10.593,1.766-14.124,5.297  c-7.945,7.062-21.186,20.303-24.717,37.959c-6.179,26.483,3.531,58.262,26.483,90.041s67.09,82.979,144.772,105.048  c24.717,7.062,44.138,2.648,60.028-7.062c12.359-7.945,20.303-20.303,22.952-33.545l2.648-12.359  c0.883-3.531-0.883-7.945-4.414-9.71l-55.614-25.6c-3.531-1.766-7.945-0.883-10.593,2.648l-22.069,28.248  c-1.766,1.766-4.414,2.648-7.062,1.766c-15.007-5.297-65.324-26.483-92.69-79.448c-0.883-2.648-0.883-5.297,0.883-7.062  l21.186-23.834c1.766-2.648,2.648-6.179,1.766-8.828l-25.6-57.379C193.324,138.593,190.676,135.945,187.145,135.945"
                            />
                          </svg>
                          Chat with us
                        </button>
                      </div>
                    )}
                    {/* {step > 0 && step <= questions.length && (
                      <>
                        <div className="font-semibold text-sm">
                          {questions[step - 1]}
                        </div>
                        <input
                          type="text"
                          value={userResponse}
                          onChange={(e) => setUserResponse(e.target.value)}
                          placeholder="Your answer..."
                          className="border rounded p-2 text-sm shadow-inner outline-none bg-gray-50 focus:bg-white"
                        />
                        {errorMessage && (
                          <div className="text-red-500 text-xs">
                            {errorMessage}
                          </div>
                        )}
                        {step === questions.length ? (
                          <button
                            type="button"
                            className="border border-[var(--blue-color)] hover:text-[#0070f0] bg-[#0070f0] text-white transition duration-300 hover:bg-white rounded  py-2"
                            onClick={onSubmit}
                            disabled={isLoading}
                          >
                            {isLoading ? "Submitting..." : "Submit"}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="border border-[var(--blue-color)] text-[#0070f0] rounded  py-2"
                            onClick={handleSendMessage}
                          >
                            Next
                          </button>
                        )}
                      </>
                    )} */}
                    {step > 0 && step <= questions.length && (
                      <>
                        <div className="font-semibold text-sm">
                          {questions[step - 1]}
                        </div>

                        {/* <div className="flex items-center space-x-2">
      <input
        type="text"
        value={editedCountryCode}
        onChange={(e) => setEditedCountryCode(e.target.value)}  // Allow user to edit country code
        placeholder="Country Code"
        className="border rounded p-2 text-sm shadow-inner outline-none bg-gray-50 focus:bg-white w-20"
      />
    </div> */}

                        {/* Display phone number input only at step 3 */}
                        {step === 3 && (
                          <div className="flex items-center space-x-2 mt-2">
                            {/* Country Code Input */}
                            <div className="flex items-center">
                              <input
                                type="text"
                                value={editedCountryCode || countryCode}
                                onChange={(e) =>
                                  setEditedCountryCode(e.target.value)
                                } // Allow user to edit country code
                                placeholder="Country Code"
                                className="border rounded p-2 text-sm shadow-inner outline-none bg-gray-50 focus:bg-white w-16"
                              />
                            </div>

                            {/* Phone Number Input */}
                            <input
                              type="text"
                              value={userResponse}
                              onChange={(e) => setUserResponse(e.target.value)} // Capture user response for other questions
                              placeholder="Phone number"
                              className="border rounded p-2 text-sm shadow-inner outline-none bg-gray-50 focus:bg-white flex-1"
                            />
                          </div>
                        )}

                        {/* User response input */}
                        {step !== 3 && (
                          <input
                            type="text"
                            value={userResponse}
                            onChange={(e) => setUserResponse(e.target.value)} // Capture user response for other questions
                            placeholder="Your answer..."
                            className="border rounded p-2 text-sm shadow-inner outline-none bg-gray-50 focus:bg-white"
                          />
                        )}

                        {/* Error message display */}
                        {errorMessage && (
                          <div className="text-red-500 text-xs">
                            {errorMessage}
                          </div>
                        )}

                        {/* Submit or Next button */}
                        {step === questions.length ? (
                          <button
                            type="button"
                            className="border border-[var(--blue-color)] hover:text-[#0070f0] bg-[#0070f0] text-white transition duration-300 hover:bg-white rounded py-2"
                            onClick={onSubmit}
                            disabled={isLoading}
                          >
                            {isLoading ? "Submitting..." : "Submit"}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="border border-[var(--blue-color)] text-[#0070f0] rounded py-2"
                            onClick={handleSendMessage}
                          >
                            Next
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div
        className="fixed bottom-20 right-3 rounded-full w-12 h-12 flex items-center justify-center cursor-pointer bg-white border border-[#0070f0] shadow-xl z-40"
        onClick={toggleChatBox}
      > */}
       <div
        className="fixed bottom-8 right-3 rounded-full w-12 h-12 flex items-center justify-center cursor-pointer bg-white border border-[#0070f0] shadow-xl z-40"
        onClick={toggleChatBox}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          width="23px"
          height="23px"
          viewBox="0 0 111.686 122.879"
        >
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop
                offset="0%"
                style={{ stopColor: "#0070f0", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "#67abf9", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
          <g>
            <path
              d="M83.896,5.08H27.789c-12.491,0-22.709,10.219-22.709,22.71v40.079c0,12.489,10.22,22.71,22.709,22.71h17.643 c-2.524,9.986-5.581,18.959-14.92,27.241c17.857-4.567,31.642-13.8,41.759-27.241h3.051c12.488,0,31.285-10.219,31.285-22.71V27.79 C106.605,15.299,96.387,5.08,83.896,5.08L83.896,5.08z M81.129,41.069c-4.551,0-8.24,3.691-8.24,8.242s3.689,8.242,8.24,8.242 c4.553,0,8.242-3.691,8.242-8.242S85.682,41.069,81.129,41.069L81.129,41.069z M30.556,41.069c-4.552,0-8.242,3.691-8.242,8.242 s3.69,8.242,8.242,8.242c4.551,0,8.242-3.691,8.242-8.242S35.107,41.069,30.556,41.069L30.556,41.069z M55.843,41.069 c-4.551,0-8.242,3.691-8.242,8.242s3.691,8.242,8.242,8.242c4.552,0,8.241-3.691,8.241-8.242S60.395,41.069,55.843,41.069 L55.843,41.069z M27.789,0h56.108h0.006v0.02c7.658,0.002,14.604,3.119,19.623,8.139l-0.01,0.01 c5.027,5.033,8.148,11.977,8.15,19.618h0.02v0.003h-0.02v40.079h0.02v0.004h-0.02c-0.004,8.17-5.68,15.289-13.24,20.261 c-7.041,4.629-15.932,7.504-23.104,7.505v0.021H75.32v-0.021h-0.576c-5.064,6.309-10.941,11.694-17.674,16.115 c-7.443,4.888-15.864,8.571-25.31,10.987l-0.004-0.016c-1.778,0.45-3.737-0.085-5.036-1.552c-1.852-2.093-1.656-5.292,0.437-7.144 c4.118-3.651,6.849-7.451,8.826-11.434c1.101-2.219,1.986-4.534,2.755-6.938h-10.95h-0.007v-0.021 c-7.656-0.002-14.602-3.119-19.622-8.139C3.138,82.478,0.021,75.53,0.02,67.871H0v-0.003h0.02V27.79H0v-0.007h0.02 C0.021,20.282,3.023,13.46,7.878,8.464C7.967,8.36,8.059,8.258,8.157,8.16c5.021-5.021,11.968-8.14,19.628-8.141V0H27.789L27.789,0 z"
              fill="url(#gradient)"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default WhatsAppChat;
