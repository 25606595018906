
export const API_URL =  process.env.NEXT_PUBLIC_MAIN_API_URL;

export const fetchData = async (searchQuery: string) => {
  try {
    const response = await fetch(`${API_URL}/data/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ searchQuery }),
    });
    return response.json();
    // return response.data.data.location; // Assuming response.data contains the structure you provided
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchCategory = async (categorySearch: string) => {
  try {
    const response = await fetch(`${API_URL}/data/category`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ categorySearch }),
    });
    return response.json();
    // return response.data.data.location; // Assuming response.data contains the structure you provided
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchAllCategories = async () => {
  try {
    // Primary API request
    const primaryResponse = await fetch(`${process.env.AWS_API_URL}/cache_json/asdasd12asdasdasass.json`);
    
    if (primaryResponse.ok) {
      // Read the response as JSON
      const primaryData = await primaryResponse.json();
      if (primaryData) {
        return primaryData.data;
      } else {
        throw new Error(`Error in fetching categories from primary API: ${primaryData?.message || 'Unknown error'}`);
      }
    } else {
      throw new Error(`Primary API request failed: ${primaryResponse.statusText}`);
    }
  } catch (primaryError) {
    console.warn("Primary API request failed, falling back to secondary API:", primaryError);
    
    try {
      // Secondary API request (fallback)
      const secondaryResponse = await fetch(`${API_URL}/cron-local/category`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!secondaryResponse.ok) {
        throw new Error(`Secondary API request failed: ${secondaryResponse.statusText}`);
      }

      const secondaryData = await secondaryResponse.json();
      if (secondaryData.success) {
        return secondaryData.data;
      } else {
        throw new Error(`Error in fetching categories from secondary API: ${secondaryData.message}`);
      }
    } catch (secondaryError) {
      console.error("Both primary and secondary API requests failed:", secondaryError);
      throw secondaryError;
    }
  }
};

export const fetchAllCountries = async () => {
  try {
    const response = await fetch(`${API_URL}/b2b/get-all-country`, {
      next: { revalidate: 24 * 3600 },
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const responseData = await response.json();
    if (responseData.success) {
      return responseData.data.c_l; // Assuming responseData.data.c_l is an array of countries
    } else {
      throw new Error("Error in fetching countries: " + responseData.message);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchCountryLeads = async (countrySlug: any) => {
  try { 
    const response = await fetch(`${API_URL}/b2b/get-all-leads/${countrySlug}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });


    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const responseData = await response.json();
    return responseData; // Assuming responseData contains data for leads of the specified country
  } catch (error) {
    console.error("Error fetching country data:", error);
    throw error;
  }
};


export const fetchStateLeads: (countrySlug: string, stateSlug: string) => Promise<any> = async (countrySlug, stateSlug) => {
  try {
    const response = await fetch(`${API_URL}/b2b/get-all-leads/${countrySlug}/${stateSlug}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const responseData = await response.json();
    return responseData; // Assuming responseData contains data for leads of the specified state
  } catch (error) {
    console.error("Error fetching state data:", error);
    throw error;
  }
};

export const fetchCityLeads: (countrySlug: string, stateSlug: string, citySlug: string) => Promise<any> = async (countrySlug, stateSlug, citySlug) => {
  try {
    const response = await fetch(`${API_URL}/b2b/get-all-leads/${countrySlug}/${stateSlug}/${citySlug}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const responseData = await response.json();
    return responseData; // Assuming responseData contains data for leads of the specified state
  } catch (error) {
    console.error("Error fetching state data:", error);
    throw error;
  }
};







