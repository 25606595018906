"use client";

import React from "react";
import canada from "../assets/images/canada_flag.svg";
import usa from "../assets/images/usa_flag.svg";
import india from "../assets/images/india_flag.svg";
import Link from "next/link";
import Image from "next/image";
import { useUserLocation } from "../context/UserLocationContext";

export default function Footer() {
  const userLocation = useUserLocation();

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div>
        <section className="py-10 px-6 text-center text-white gradient-background">
          <div className="text-2xl font-bold ">
            Are You Looking For Something Else?
          </div>
          <p className="text-lg mb-8">
            Reach out to us now if you have any special custom data requests.
            We&apos;re here to help.
          </p>
          <Link
            href="/contact-us" // Update this to the actual contact page URL
            className=" bg-white text-[#0070f0]  py-2 px-4 rounded-lg text-lg"
          >
            <button>Contact Us Now</button>
          </Link>
        </section>
      </div>

      <footer className="text-center md:text-left relative border-[2px] text-neutral-600 bg-center flex flex-col items-center justify-center dark:text-[var(--text-color)] lg:text-left">
        <div className="max-w-[1880px] mx-auto px-6 md:px-10 lg:px-20 py-3 mt-5">
          {/* Main container div: holds the entire content of the footer */}
          <div className="flex flex-wrap justify-between  gap-y-8">
            {/* Products section */}
            <div className="flex-1 min-w-[280px] max-w-sm md:px-0 lg:px-20 md:max-w-none">
              <Link href="/" className="">
                <div className="text-[var(--title-color)] text-3xl mb-2">
                  <span className="gradient-new-text">Biz</span>Dataset
                </div>
              </Link>
              <p className="text-sm">
                {/* BizDataset provides reliable business datasets tailored to your business needs. Empower your sales and marketing efforts now with a comprehensive contact database, including phone numbers, email lists, and more. */}
                We deliver reliable business datasets to support your sales and
                marketing efforts worldwide.
              </p>

              {/* <div className="flex md:items-center md:justify-start justify-center items-center  mt-6">
                <a
                  href="https://www.facebook.com/bizdataset/"
                  className="mr-6 text-neutral-600 dark:text-[var(--text-color)]"
                  aria-label="Facebook"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                </a>

                <a
                  href=" https://www.instagram.com/bizdataset/"
                  className="mr-6 text-neutral-600 dark:text-[var(--text-color)]"
                  aria-label="Instagram"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>

                <a
                  href="https://x.com/bizdataset"
                  className="mr-6 text-neutral-600 dark:text-[var(--text-color)]"
                  aria-label="X/Twitter"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="18"
                    height="18"
                    viewBox="0 0 50 50"
                  >
                    <path
                      d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"
                      fill="#5E587A"
                    ></path>
                  </svg>
                </a>

                <a
                  href="#"
                  aria-label="LinkedIn"
                  className="mr-6 text-neutral-600 dark:text-[var(--text-color)]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                  </svg>
                </a>

                <a
                  href="https://www.youtube.com/@bizdataset"
                  className="mr-6 text-neutral-600 dark:text-[var(--text-color)] "
                  aria-label="YouTube"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    className="h-5 mt-1 w-5"
                    viewBox="0 0 50 50"
                  >
                    <path d="M 24.402344 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.402344 16.898438 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.902344 40.5 17.898438 41 24.5 41 C 31.101563 41 37.097656 40.5 40.597656 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.097656 35.5 C 45.5 33 46 29.402344 46.097656 24.902344 C 46.097656 20.402344 45.597656 16.800781 45.097656 14.300781 C 44.699219 12.101563 42.800781 10.5 40.597656 10 C 37.097656 9.5 31 9 24.402344 9 Z M 24.402344 11 C 31.601563 11 37.398438 11.597656 40.199219 12.097656 C 41.699219 12.5 42.898438 13.5 43.097656 14.800781 C 43.699219 18 44.097656 21.402344 44.097656 24.902344 C 44 29.199219 43.5 32.699219 43.097656 35.199219 C 42.800781 37.097656 40.800781 37.699219 40.199219 37.902344 C 36.597656 38.601563 30.597656 39.097656 24.597656 39.097656 C 18.597656 39.097656 12.5 38.699219 9 37.902344 C 7.5 37.5 6.300781 36.5 6.101563 35.199219 C 5.300781 32.398438 5 28.699219 5 25 C 5 20.398438 5.402344 17 5.800781 14.902344 C 6.101563 13 8.199219 12.398438 8.699219 12.199219 C 12 11.5 18.101563 11 24.402344 11 Z M 19 17 L 19 33 L 33 25 Z M 21 20.402344 L 29 25 L 21 29.597656 Z"></path>
                  </svg>
                </a>
              </div> */}
            </div>

            {/* Navigation section */}
            <div className="flex-1 min-w-[200px] max-w-sm md:max-w-none">
              <nav>
                <p className="mb-2">
                  <Link
                    href="/"
                    className="text-[var(--text-color)] text-sm block"
                  >
                    Home
                  </Link>
                </p>
                <p className="mb-2">
                  <Link
                    href="/contact-us"
                    className="text-[var(--text-color)] text-sm block"
                  >
                    Contact Us
                  </Link>
                </p>
                <p className="mb-2">
                  <Link
                    href="/privacy-policy"
                    className="text-[var(--text-color)] text-sm block"
                  >
                    Privacy Policy
                  </Link>
                </p>
                <p className="mb-2">
                  <Link
                    href="/terms-and-conditions"
                    className="text-[var(--text-color)] text-sm block"
                  >
                    Terms and Conditions
                  </Link>
                </p>
                <p className="mb-2">
                  <Link
                    href="/cancellation-refund-policy"
                    className="text-[var(--text-color)] text-sm block"
                  >
                    Cancellation Refund Policy
                  </Link>
                </p>
                <p className="mb-2">
                  <Link
                    href="/blog"
                    className="text-[var(--text-color)] text-sm block"
                  >
                    Blogs
                  </Link>
                </p>
              </nav>
            </div>

            {/* Company section */}
            <div className="flex-1 min-w-[200px] max-w-sm md:max-w-none">
              <nav>
                <p className="mb-2">
                  <Link
                    href="/b2b-list"
                    className="text-[var(--text-color)] text-sm block"
                  >
                    B2B Datasets
                  </Link>
                </p>
                <p className="mb-2">
                  <Link
                    href="/stats"
                    className="text-[var(--text-color)] text-sm block"
                  >
                    Social Media Stats
                  </Link>
                </p>
                <p className="mb-2">
                  <Link
                    href={`/b2b-list/${(
                      userLocation?.country_name ?? "united-states"
                    )
                      .toLowerCase()
                      .replace(/\s+/g, "-")}-database`}
                    className="text-neutral-600 dark:text-[var(--text-color)] text-sm"
                  >
                    Datasets by Country
                  </Link>
                </p>
                <p className="mb-2">
                  <Link
                    href={`/b2b-list/${(
                      userLocation?.country_name ?? "united-states"
                    )
                      .toLowerCase()
                      .replace(/\s+/g, "-")}/${(
                      userLocation?.region ?? "california"
                    )
                      .toLowerCase()
                      .replace(/\s+/g, "-")}-database`}
                    className="text-neutral-600 dark:text-[var(--text-color)] text-sm"
                  >
                    Datasets by State
                  </Link>
                </p>
                <p className="mb-2">
                  <Link
                    href={`/b2b-list/${(
                      userLocation?.country_name ?? "united-states"
                    )
                      .toLowerCase()
                      .replace(/\s+/g, "-")}/${(
                      userLocation?.region ?? "california"
                    )
                      .toLowerCase()
                      .replace(/\s+/g, "-")}/${(
                      userLocation?.city ?? "los-angeles"
                    )
                      .toLowerCase()
                      .replace(/\s+/g, "-")}-database`}
                    className="text-neutral-600 dark:text-[var(--text-color)] text-sm"
                  >
                    Datasets by City
                  </Link>
                </p>
                {/* <p className="mb-2">
        <Link href="/category" className="text-neutral-600 dark:text-[var(--text-color)] text-sm">Datasets by Category</Link>
      </p>
      <p className="mb-2">
        <Link href="/platform" className="text-neutral-600 dark:text-[var(--text-color)] text-sm">Datasets by Platform</Link>
      </p> */}
              </nav>
            </div>

            {/* Pages section */}
            {/* <div className="flex-1 min-w-[200px] max-w-sm md:max-w-none">
    <nav>
      <p className="mb-2">
        <Link href="/email-list" className="text-neutral-600 dark:text-[var(--text-color)] text-sm">Email List</Link>
      </p>
      <p className="mb-2">
        <Link href="/sms-marketing-database" className="text-neutral-600 dark:text-[var(--text-color)] text-sm">SMS Marketing</Link>
      </p>
      <p className="mb-2">
        <Link href="/whatsapp-marketing-database" className="text-neutral-600 dark:text-[var(--text-color)] text-sm">WhatsApp Marketing</Link>
      </p>
      <p className="mb-2">
        <Link href="/facebook-marketing-database" className="text-neutral-600 dark:text-[var(--text-color)] text-sm">Facebook Marketing</Link>
      </p>
      <p className="mb-2">
        <Link href="/linkedin-marketing-database" className="text-neutral-600 dark:text-[var(--text-color)] text-sm">Linkedin Marketing</Link>
      </p>
    </nav>
  </div> */}

            {/* Newsletter and Locations */}
            <div className="flex-1 min-w-[200px] max-w-sm md:max-w-none">
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-center md:justify-start gap-4">
                  <Image
                    src={usa}
                    alt="USA Flag"
                    className="w-4 h-4"
                    title="USA Flag"
                  />
                  <p className="text-sm tracking-wide text-[var(--text-color)]">
                    CA, USA
                  </p>
                </div>
                <div className="flex items-center justify-center md:justify-start gap-4">
                  <Image
                    src={india}
                    alt="India Flag"
                    className="w-4 h-4"
                    title="India Flag"
                  />
                  <p className="text-sm tracking-wide text-[var(--text-color)]">
                    BLR, India
                  </p>
                </div>
                <div className="flex items-center justify-center md:justify-start gap-4">
                  <Image
                    src={canada}
                    alt="Canada Flag"
                    className="w-4 h-4"
                    title="Canada Flag"
                  />
                  <p className="text-sm tracking-wide text-[var(--text-color)]">
                    ONT, Canada
                  </p>
                </div>
                <div className="flex md:items-center md:justify-start justify-center items-center mt-4">
                  <a
                    href="https://www.facebook.com/bizdataset/"
                    className="mr-4 text-neutral-600 dark:text-[var(--text-color)]"
                    aria-label="Facebook"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                    </svg>
                  </a>

                  <a
                    href=" https://www.instagram.com/bizdataset/"
                    className="mr-4 text-neutral-600 dark:text-[var(--text-color)]"
                    aria-label="Instagram"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                    </svg>
                  </a>

                  <a
                    href="https://x.com/bizdataset"
                    className="mr-4 text-neutral-600 dark:text-[var(--text-color)]"
                    aria-label="X/Twitter"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="18"
                      height="18"
                      viewBox="0 0 50 50"
                    >
                      <path
                        d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"
                        fill="#5E587A"
                      ></path>
                    </svg>
                  </a>

                  <a
                    href="https://www.linkedin.com/company/bizdataset/"
                    aria-label="LinkedIn"
                    className="mr-4 text-neutral-600 dark:text-[var(--text-color)]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                    </svg>
                  </a>

                  <a
                    href="https://www.youtube.com/@bizdataset"
                    className="mr-4 text-neutral-600 dark:text-[var(--text-color)] "
                    aria-label="YouTube"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      className="h-5 mt-1 w-5"
                      viewBox="0 0 50 50"
                    >
                      <path d="M 24.402344 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.402344 16.898438 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.902344 40.5 17.898438 41 24.5 41 C 31.101563 41 37.097656 40.5 40.597656 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.097656 35.5 C 45.5 33 46 29.402344 46.097656 24.902344 C 46.097656 20.402344 45.597656 16.800781 45.097656 14.300781 C 44.699219 12.101563 42.800781 10.5 40.597656 10 C 37.097656 9.5 31 9 24.402344 9 Z M 24.402344 11 C 31.601563 11 37.398438 11.597656 40.199219 12.097656 C 41.699219 12.5 42.898438 13.5 43.097656 14.800781 C 43.699219 18 44.097656 21.402344 44.097656 24.902344 C 44 29.199219 43.5 32.699219 43.097656 35.199219 C 42.800781 37.097656 40.800781 37.699219 40.199219 37.902344 C 36.597656 38.601563 30.597656 39.097656 24.597656 39.097656 C 18.597656 39.097656 12.5 38.699219 9 37.902344 C 7.5 37.5 6.300781 36.5 6.101563 35.199219 C 5.300781 32.398438 5 28.699219 5 25 C 5 20.398438 5.402344 17 5.800781 14.902344 C 6.101563 13 8.199219 12.398438 8.699219 12.199219 C 12 11.5 18.101563 11 24.402344 11 Z M 19 17 L 19 33 L 33 25 Z M 21 20.402344 L 29 25 L 21 29.597656 Z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mt-8 mb-4">
            {/* Divider */}
            <div className="absolute top-0 left-0 right-0 border-t border-gray-300"></div>

            {/* Disclaimer Text */}
            <div className="pt-4 text-center text-[12px] text-gray-600">
              <b>Disclaimer:</b> We collect data from publicly accessible
              sources. The company names, trademarks, or datasets mentioned on
              our site are for illustrative purposes only and do not imply that
              we scrape them.
            </div>
          </div>

          {/* Copyright and Social Media */}
          <div className="text-center mt-4  flex  items-center justify-center">
            <span className="text-sm sm:mr-4">
              Copyright © {currentYear}. All Rights Reserved.
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
