
import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import { fetchCategory, fetchData } from "../api/search";
import { useUserLocation } from "@/context/UserLocationContext";
import slugify from 'slugify'; // Importing slugify for more user-friendly URLs

interface SearchResult {
  d: string;
  c: string;
}

const HeaderSearchBar = () => {

    const [searchLocation, setSearchLocation] = useState({
        location: "",
        result: {},
      });
      const [inputModified, setInputModified] = useState(false);
      const [searchCategory, setSearchCategory] = useState({
        category: "",
        result: {},
      });
      const [searchResultsLocation, setSearchResultsLocation] = useState<
        SearchResult[]
      >([]);
      const [searchResultsCategory, setSearchResultsCategory] = useState<
        SearchResult[]
      >([]);
      const userLocation = useUserLocation();
      const isSearchDisabled = !searchLocation.location || !searchCategory.category;
    
      useEffect(() => {
        async function setSerachlocation() {
          try {
            const response = await fetchData(searchLocation.location);
            if (response.success && searchLocation.location.length > 0) {
              setSearchResultsLocation(response.data.location);
            } else {
              setSearchResultsLocation([]);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            setSearchResultsLocation([]);
          }
        }
    
        const id = setTimeout(() => {
          if (searchLocation.location.length > 0) {
            setSerachlocation();
          } else {
            setSearchResultsLocation([]);
          }
        }, 100);
        return () => {
          clearTimeout(id);
          setSearchResultsLocation([]);
        };
      }, [searchLocation.location]);
    
      // useEffect(() => {
      //   async function setSerachCategory() {
      //     try {
      //       const response = await fetchCategory(searchCategory.category);
      //       if (response.success) {
      //         setSearchResultsCategory(response.data.categories);
      //       } else {
      //         setSearchResultsCategory([]);
      //       }
      //     } catch (error) {
      //       console.error("Error fetching data:", error);
      //       setSearchResultsCategory([]);
      //     }
      //   }
    
      //   const id = setTimeout(() => {
      //     if (searchCategory.category.length > 0) {
      //       setSerachCategory();
      //     } else {
      //       setSearchResultsCategory([]);
      //     }
      //   }, 100);
    
      //   return () => {
      //     clearTimeout(id);
      //     setSearchResultsCategory([]);
      //   };
      // }, [searchCategory.category]);
const [isDropdownSelection, setIsDropdownSelection] = useState(false);


    
      useEffect(() => {
        async function setSearchCategoryData() {
          try {
            const response = await fetchCategory(searchCategory.category);
            if (response.success) {
              setSearchResultsCategory(response.data.categories);
      
              // Check if the user's input exactly matches a category name
              const exactMatch = response.data.categories.find(
                (cat: { c: string }) =>
                  cat.c.toLowerCase() === searchCategory.category.toLowerCase()
              );
      
              if (exactMatch) {
                // Auto-fill the input if an exact match is found
                setSearchCategory((prevSearchCategory) => ({
                  ...prevSearchCategory,
                  category: exactMatch.c,
                  result: exactMatch,
                }));
                setHighlightedCatagory(-1); // Reset highlighting if needed
              }
            } else {
              setSearchResultsCategory([]);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            setSearchResultsCategory([]);
          }
        }
      
        const id = setTimeout(() => {
          if (searchCategory.category.length > 0 && !isDropdownSelection) {
            setSearchCategoryData(); // Call API only if not selecting from the dropdown
          } else {
            setSearchResultsCategory([]);
          }
        }, 100);
      
        return () => {
          clearTimeout(id);
          setSearchResultsCategory([]);
        };
      }, [searchCategory.category, isDropdownSelection]); // Add `isDropdownSelection` to dependencies
    
      const [highlightedIndex, setHighlightedIndex] = useState(-1);
    
      // const handleKeyDown = (e: { key: string; preventDefault: () => void }) => {
      //   if (e.key === "ArrowDown") {
      //     e.preventDefault();
      //     setHighlightedIndex((prevIndex) =>
      //       prevIndex < searchResultsLocation.length - 1 ? prevIndex + 1 : prevIndex
      //     );
      //   } else if (e.key === "ArrowUp") {
      //     e.preventDefault();
      //     setHighlightedIndex((prevIndex) =>
      //       prevIndex > 0 ? prevIndex - 1 : prevIndex
      //     );
      //   } else if (e.key === "Enter") {
      //     e.preventDefault();
      //     if (
      //       highlightedIndex >= 0 &&
      //       highlightedIndex < searchResultsLocation.length
      //     ) {
      //       selectItem(searchResultsLocation[highlightedIndex]);
      //     }
      //   }
      // };
    
      // const selectItem = (result: SearchResult) => {
      //   setSearchLocation({
      //     ...searchLocation,
      //     location: result.d,
      //     result: result,
      //   });
      //   setSearchResultsLocation([]);
      //   setHighlightedIndex(-1); // Reset highlighted index after selection
      // };
    
    
      // Function to select a location item
    // const selectItem = (result: SearchResult) => {
    //   setSearchLocation({
    //     location: result.d || "",
    //     result: result,
    //   });
    //   setSearchResultsLocation([]);
    //   setHighlightedIndex(-1);
    // };
    
      // const [highlightedCatagory, setHighlightedCatagory] = useState(-1);
    
      // const handleKeyboardNavigation = (e: {
      //   key: string;
      //   preventDefault: () => void;
      // }) => {
      //   if (e.key === "ArrowDown") {
      //     e.preventDefault();
      //     setHighlightedCatagory((prevIndex) =>
      //       prevIndex < searchResultsCategory.length - 1 ? prevIndex + 1 : prevIndex
      //     );
      //   } else if (e.key === "ArrowUp") {
      //     e.preventDefault();
      //     setHighlightedCatagory((prevIndex) =>
      //       prevIndex > 0 ? prevIndex - 1 : prevIndex
      //     );
      //   } else if (e.key === "Enter") {
      //     e.preventDefault();
      //     if (
      //       highlightedCatagory >= 0 &&
      //       highlightedCatagory < searchResultsCategory.length
      //     ) {
      //       handleItemSelection(searchResultsCategory[highlightedCatagory]);
      //     }
      //   }
      // };
    
      // const handleItemSelection = (result: SearchResult) => {
      //   setSearchCategory({
      //     ...searchCategory,
      //     category: result.c,
      //     result: result,
      //   });
      //   setSearchResultsCategory([]);
      //   setHighlightedCatagory(-1); // Reset highlighted index after selection
      // };
    
    
      // const handleItemSelection = (result: SearchResult) => {
      //   setSearchCategory({
      //     category: result.c || "",  // Ensure category is either valid or an empty string
      //     result: result,
      //   });
      //   setSearchResultsCategory([]);
      //   setHighlightedCatagory(-1);
      // };
      

      const [highlightedCatagory, setHighlightedCatagory] = useState(-1);
  
  const handleKeyboardNavigation = (e: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedCatagory((prevIndex) =>
        prevIndex < searchResultsCategory.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedCatagory((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (
        highlightedCatagory >= 0 &&
        highlightedCatagory < searchResultsCategory.length
      ) {
        handleItemSelection(searchResultsCategory[highlightedCatagory]);
      }
    }
  };
  
  // const handleItemSelection = (result: SearchResult) => {
  //   setSearchCategory({
  //     category: result.c || "", // Ensure category is either valid or an empty string
  //     result: result,
  //   });
  //   setSearchResultsCategory([]);
  //   setHighlightedCatagory(-1);
  // };

useEffect(() => {
    const savedCategory = localStorage.getItem("savedCategory");
    if (savedCategory) {
      const parsedCategory = JSON.parse(savedCategory);
      setSearchCategory({
        category: parsedCategory.c,
        result: parsedCategory,
      });
      // Set the flag to avoid triggering the API call
      setIsDropdownSelection(true);
    } else {
      // If no saved category in localStorage, ensure flag is reset
      setIsDropdownSelection(false);
    }
  }, []);
  

  const handleItemSelection = (selectedCategory: SearchResult) => {
    setSearchCategory({
      category: selectedCategory.c,
      result: selectedCategory,
    });
  
    // Save the selected category in localStorage
    localStorage.setItem("savedCategory", JSON.stringify(selectedCategory));
  
    // Optionally reset the search results
    setSearchResultsCategory([]);
    setHighlightedCatagory(-1);
  
    // Set flag to true to indicate that the user selected from the dropdown
    setIsDropdownSelection(true);
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchCategory({
      ...searchCategory,
      category: value,
    });
  
    setSearchResultsLocation([]); // Close location dropdown
    setHighlightedIndex(-1);
    setIsDropdownSelection(false); // Reset flag when input is modified
  
    // If the input value matches a category, set it automatically
    const matchedCategory = searchResultsCategory.find(
      (cat) => cat.c.toLowerCase() === value.toLowerCase()
    );
    if (matchedCategory) {
      handleItemSelection(matchedCategory);
    }
  };


  // const clearCatagoryInput = () => {
  //   setSearchCategory({
  //     category: "", // Clear both user input and default value
  //     result: {},
  //   });
  //   setSearchResultsCategory([]);
  // };
  const clearCatagoryInput = () => {
    setSearchCategory({
      category: "", // Clear both user input and default value
      result: {},
    });
    setSearchResultsCategory([]);

    // Clear the saved category from localStorage
    localStorage.removeItem("savedCategory");
  };

    
      const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
      const [isButtonVisible, setIsButtonVisible] = useState(false);
      
      useEffect(() => {
        const handleScroll = () => {
          const shouldBeVisible = window.scrollY > 570;
    
          if (isSearchBarVisible) {
            setIsSearchBarVisible(false);
          }
    
          if (shouldBeVisible !== isButtonVisible) {
            setIsButtonVisible(shouldBeVisible);
          }
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, [isSearchBarVisible, isButtonVisible]);
    
      const toggleSearchBar = () => {
        setIsSearchBarVisible(!isSearchBarVisible);
        setIsButtonVisible(false);
      };
    
      const searchBarRef = useRef<HTMLDivElement>(null);
    
      const handleClickOutsides = (event: { target: any; }) => {
        if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
          setIsSearchBarVisible(false);
          setIsButtonVisible(true);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsides);
        return () => {
          document.removeEventListener('mousedown', handleClickOutsides);
        };
      }, []);
    
    

      function normalizeString(str: string) {
        return slugify(str, { lower: true });
      }

      function setRedirectSlug(objlocation: any, objcategroy: any) {
        try {
          let strlast = normalizeString(`${objcategroy.result.s}`); // Normalize category name
          let strdir = "";
      
          if (objlocation.result.cy_s) {
            strlast = `list-of-${strlast}-in-${normalizeString(objlocation.result.cy_s)}`; // Normalize city
            strdir = `${normalizeString(objlocation.result.c_s)}/${normalizeString(objlocation.result.s_s)}/`; // Normalize location parts
          } else if (objlocation.result.s_s) {
            strlast = `list-of-${strlast}-in-${normalizeString(objlocation.result.s_s)}`; // Normalize state
            strdir = `${normalizeString(objlocation.result.c_s)}/`; // Normalize country
          } else if (objlocation.result.c_s) {
            strlast = `list-of-${strlast}-in-${normalizeString(objlocation.result.c_s)}`; // Normalize country
          }
      
          return `b2b-database/${strdir}${strlast}`;
        } catch (error) {
          console.error("Error fetching data:", error);
          throw error; // Propagate the error
        }
      }
      
      function setRedirectLocationSlug(objlocation: any) {
        try {
          let strlast = ""; // Initialize the last part of the slug
          let strdir = ""; // Initialize the directory part of the slug
      
          if (objlocation.result.cy_s) {
            strlast = normalizeString(`${objlocation.result.cy_s}`); // Normalize city
            strdir = `${normalizeString(objlocation.result.c_s)}/${normalizeString(objlocation.result.s_s)}/`; // Normalize location parts
          } else if (objlocation.result.s_s) {
            strlast = normalizeString(`${objlocation.result.s_s}`); // Normalize state
            strdir = `${normalizeString(objlocation.result.c_s)}/`; // Normalize country
          } else if (objlocation.result.c_s) {
            strlast = normalizeString(`${objlocation.result.c_s}`); // Normalize country
          }
      
          return `b2b-list/${strdir}${strlast}-database`;
        } catch (error) {
          console.error("Error fetching data:", error);
          throw error; // Propagate the error
        }
      }
      
      const isLocationSelected = !!searchLocation.location;
      const isCategorySelected = !!searchCategory.category;
      
      const getRedirectUrl = () => {
        // Check if searchCategory and searchLocation have valid selected values
        const isCategoryValid =
          searchCategory.result && "c" in searchCategory.result;
        const isLocationValid =
          searchLocation.result && "d" in searchLocation.result;
      
        if (isLocationSelected && isLocationValid) {
          if (isCategorySelected && isCategoryValid) {
            // Both location and valid category are selected
            return `/${setRedirectSlug(searchLocation, searchCategory)}`;
          } else {
            // Location is selected, but category is either invalid or not selected
            return `/${setRedirectLocationSlug(searchLocation)}`;
          }
        } else if (isCategorySelected && isCategoryValid) {
          // Location is not selected, but valid category is selected
          return  `b2b-database/list-of-${normalizeString(
            searchCategory.category
          )}-in-united-states`;
        } else {
          // Default URL or fallback
          return "#";
        }
      };
    
    
      // useEffect(() => {
      //   if (userLocation?.country_name && !inputModified) {
      //     setSearchLocation((prev) => ({
      //       ...prev,
      //       location: userLocation.country_name,
      //     }));
      //   }
      // }, [userLocation, inputModified, setSearchLocation]);

      useEffect(() => {
        if (!inputModified) {
          setSearchLocation((prev) => ({
            ...prev,
            location: userLocation?.country_name || "united-states", // Set a default value here
          }));
        }
      }, [userLocation, inputModified, setSearchLocation]);
    
      useEffect(() => {
        if (searchLocation.location && searchResultsLocation.length > 0) {
          const matchingResult = searchResultsLocation.find(
            (result) => result.d === searchLocation.location
          );
          if (matchingResult) {
            setSearchLocation((prev) => ({
              ...prev,
              location: matchingResult.d,
              result: matchingResult,
            }));
            setSearchResultsLocation([]);
          }
        }
      }, [
        searchResultsLocation,
        searchLocation.location,
        setSearchLocation,
        setSearchResultsLocation,
      ]);
    
      // const clearInput = () => {
      //   setSearchLocation({
      //     location: "", // Clear both user input and default value
      //     result: {},
      //   });
      //   setSearchResultsLocation([]);
      //   setInputModified(false);
      // };
    
      // const clearCatagoryInput = () => {
      //   setSearchCategory({
      //     category: "", // Clear both user input and default value
      //     result: {},
      //   });
      //   setSearchResultsCategory([]);
      // };
    


      useEffect(() => {
        const savedLocation = localStorage.getItem("selectedLocation");
        if (savedLocation) {
          setSearchLocation(JSON.parse(savedLocation)); // Parse and set saved location
        } else if (userLocation?.country_name) {
          setSearchLocation({
            location: userLocation.country_name,
            result: { d: userLocation.country_name }, // You can modify based on your actual data structure
          });
        }
      }, [userLocation]);
    
      // Fetch location data based on search query
      useEffect(() => {
        async function setSearchLocationData() {
          try {
            const response = await fetchData(searchLocation.location);
            if (response.success && searchLocation.location.length > 0) {
              setSearchResultsLocation(response.data.location);
            } else {
              setSearchResultsLocation([]);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            setSearchResultsLocation([]);
          }
        }
    
        const id = setTimeout(() => {
          if (searchLocation.location.length > 0) {
            setSearchLocationData();
          } else {
            setSearchResultsLocation([]);
          }
        }, 100);
    
        return () => {
          clearTimeout(id);
          setSearchResultsLocation([]);
        };
      }, [searchLocation.location]);
    
      // Update the location in the state and localStorage when location changes
      const selectItem = (result: SearchResult) => {
        setSearchLocation({
          location: result.d || "",
          result: result,
        });
        setSearchResultsLocation([]);
        setHighlightedIndex(-1);
        // Store the selected location in localStorage
        localStorage.setItem("selectedLocation", JSON.stringify({
          location: result.d || "",
          result: result,
        }));
      };
    
      const clearInput = () => {
        setSearchLocation({
          location: "",
          result: {},
        });
        setSearchResultsLocation([]);
        setInputModified(false);
        // Clear the location in localStorage as well
        localStorage.removeItem("selectedLocation");
      };
    
      const handleKeyDown = (e: { key: string; preventDefault: () => void; }) => {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setHighlightedIndex((prevIndex) =>
            prevIndex < searchResultsLocation.length - 1 ? prevIndex + 1 : prevIndex
          );
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          setHighlightedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : prevIndex
          );
        } else if (e.key === "Enter") {
          e.preventDefault();
          if (highlightedIndex >= 0 && highlightedIndex < searchResultsLocation.length) {
            selectItem(searchResultsLocation[highlightedIndex]);
          }
        }
      };
    


  return (
    <div>

        
        {/* Center section with search bar */}
        <div className="flex-grow flex items-center justify-center space-x-6 ml-4">
          {/* Toggle search button */}
          {isButtonVisible && !isSearchBarVisible && (
            <button
              role="button"
              onClick={toggleSearchBar}
              className="bg-white w-10  sm:w-64 px-1 h-10 sm:ml-0 ml-24 sm:h-8 gap-3 border flex items-center justify-between rounded-full hover:shadow-lg transition duration-300 cursor-pointer transform hover:scale-105"
            >
              <span className="hidden  sm:inline-block text-[var(--text-color)] ml-4">
                Location
              </span>
              <div className="hidden sm:block border-l border-gray-300 h-8 ml-2"></div>
              <span className="hidden sm:inline-block text-[var(--text-color)] ml-2">
                Category
              </span>
              <div className="bg-[#000000] hover:bg-[#11181c] w-8 h-8  sm:w-6 sm:h-6 flex items-center justify-center text-white font-semibold rounded-full hover:shadow-lg transition duration-300 cursor-pointer ml-auto sm:ml-2">
                <svg
                  className="w-4 h-4 sm:w-3 sm:h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
            </button>
          )}

          {/* Search bar */}
          {isSearchBarVisible && (
            <div ref={searchBarRef} className="fixed inset-0 h-[100px]  flex justify-center mt-[60px] z-50">
              <div className="flex items-center justify-center flex-col sm:flex-row max-w-xl md:space-x-4 md:-mt-7 md:space-y-0 space-y-8 md:py-0 py-4 bg-white border md:rounded-full rounded-lg shadow-lg mb-4 sm:mb-0 w-full md:h-[60px] h-[250px] transition-all duration-300 opacity-100 scale-100 transform">
                
                <div className="flex flex-col items-center  justify-center md:border-r-2 md:pr-4">
                  <label
                    htmlFor="searchLocation"
                    className="font-semibold text-center"
                  >
                    <span className="text-[var(--text-color)]">Location</span>

                    <input
                      type="text"
                      name="location"
                      autoComplete="off"
                      value={searchLocation.location} // Reflects both default and user input
                      onChange={(e) => {
                        setInputModified(true);
                        if (e.target.value.length > 0) {
                          setSearchLocation({
                            ...searchLocation,
                            [e.target.name]: e.target.value,
                          });
                          setSearchResultsCategory([]); // Close category dropdown when typing in location
                          setHighlightedCatagory(-1); // Reset category highlighted index
                        } else {
                          setSearchLocation({
                            ...searchLocation,
                            [e.target.name]: "",
                            result: {},
                          });
                          setSearchResultsLocation([]);
                          setHighlightedIndex(-1); // Reset highlighted index
                        }
                      }}
                      placeholder="Search Location"
                      className="text-sm text-center outline-none text-[var(--text-color)] placeholder:text-center"
                      onKeyDown={handleKeyDown}
                    />
                    {/* Clear button */}
                    {searchLocation.location.length > 0 && (
                      <button
                        type="button"
                        onClick={clearInput}
                        className="absolute"
                        aria-label="Clear"
                      >

                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" fillRule="evenodd" className="text-gray-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M799.855 166.312c.023.007.043.018.084.059l57.69 57.69c.041.041.052.06.059.084a.118.118 0 0 1 0 .069c-.007.023-.018.042-.059.083L569.926 512l287.703 287.703c.041.04.052.06.059.083a.118.118 0 0 1 0 .07c-.007.022-.018.042-.059.083l-57.69 57.69c-.041.041-.06.052-.084.059a.118.118 0 0 1-.069 0c-.023-.007-.042-.018-.083-.059L512 569.926 224.297 857.629c-.04.041-.06.052-.083.059a.118.118 0 0 1-.07 0c-.022-.007-.042-.018-.083-.059l-57.69-57.69c-.041-.041-.052-.06-.059-.084a.118.118 0 0 1 0-.069c.007-.023.018-.042.059-.083L454.073 512 166.371 224.297c-.041-.04-.052-.06-.059-.083a.118.118 0 0 1 0-.07c.007-.022.018-.042.059-.083l57.69-57.69c.041-.041.06-.052.084-.059a.118.118 0 0 1 .069 0c.023.007.042.018.083.059L512 454.073l287.703-287.702c.04-.041.06-.052.083-.059a.118.118 0 0 1 .07 0Z"></path></svg>
                      </button>
                    )}

                    {searchResultsLocation.length > 0 &&
                      searchLocation.location.length > 0 && (
                        <ul className="absolute z-10 lg:mt-[4px] mt-[8px]  lg:w-[180px] lg:ml-4 ml-20 w-[150px] rounded-lg lg:rounded-none scrollbar-none bg-white border-gray-300 max-h-60 overflow-auto">
                          {searchResultsLocation.map((result, index) => (
                            <li
                              key={index}
                              className={`px-4 py-2 text-center text-sm text-[var(--title-color)] cursor-pointer ${
                                index === highlightedIndex ? "bg-gray-100" : ""
                              }`}
                              onClick={() => selectItem(result)}
                              onMouseEnter={() => setHighlightedIndex(index)}
                            >
                              {result.d}
                            </li>
                          ))}
                        </ul>
                      )}
                  </label>
                </div>

                <div className="flex flex-col items-center justify-center md:pl-7">
                  <label
                    htmlFor="searchCategory"
                    className="font-semibold text-center"
                  >
                    <span className="text-[var(--text-color)]">Category</span>
                    <input
                      type="text"
                      id="category-search"
                      name="category"
                      autoComplete="off"
                      // onChange={(e) => {
                      //   const value = e.target.value;
                      //   setSearchCategory({
                      //     ...searchCategory,
                      //     [e.target.name]: value,
                      //   });
                    
                      //   setSearchResultsLocation([]); // Close location dropdown
                      //   setHighlightedIndex(-1);
                    
                      //   // If the input value matches a category, set it automatically
                      //   const matchedCategory = searchResultsCategory.find(
                      //     (cat) => cat.c.toLowerCase() === value.toLowerCase()
                      //   );
                      //   if (matchedCategory) {
                      //     handleItemSelection(matchedCategory);
                      //   }
                      // }}
                      onChange={handleInputChange}
                      value={searchCategory.category}
                      placeholder="Search Category"
                      className="mt-1 text-sm text-center outline-none text-[var(--text-color)] max-w-[200px] placeholder:text-center"
                      onKeyDown={handleKeyboardNavigation}
                    />
          {searchCategory.category.length > 0 && (
                      <button
                        type="button"
                        onClick={clearCatagoryInput}
                        className="absolute"
                        aria-label="Clear"
                      >
                      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" fillRule="evenodd" className="text-gray-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M799.855 166.312c.023.007.043.018.084.059l57.69 57.69c.041.041.052.06.059.084a.118.118 0 0 1 0 .069c-.007.023-.018.042-.059.083L569.926 512l287.703 287.703c.041.04.052.06.059.083a.118.118 0 0 1 0 .07c-.007.022-.018.042-.059.083l-57.69 57.69c-.041.041-.06.052-.084.059a.118.118 0 0 1-.069 0c-.023-.007-.042-.018-.083-.059L512 569.926 224.297 857.629c-.04.041-.06.052-.083.059a.118.118 0 0 1-.07 0c-.022-.007-.042-.018-.083-.059l-57.69-57.69c-.041-.041-.052-.06-.059-.084a.118.118 0 0 1 0-.069c.007-.023.018-.042.059-.083L454.073 512 166.371 224.297c-.041-.04-.052-.06-.059-.083a.118.118 0 0 1 0-.07c.007-.022.018-.042.059-.083l57.69-57.69c.041-.041.06-.052.084-.059a.118.118 0 0 1 .069 0c.023.007.042.018.083.059L512 454.073l287.703-287.702c.04-.041.06-.052.083-.059a.118.118 0 0 1 .07 0Z"></path></svg>
                      </button>
                    )}
                    {searchResultsCategory.length > 0 &&
                      searchCategory.category.length > 0 && (
                        <ul className="absolute z-10 mt-4 lg:mt-1 max-w-44 lg:ml-7 ml-16 lg:w-full scrollbar-none bg-white lg:rounded-none rounded-lg max-h-60 overflow-auto">
                          {searchResultsCategory.map((result, index) => (
                           <li
                           key={index}
                           className={`px-4 py-2 text-center text-sm text-[var(--title-color)] cursor-pointer ${
                             index === highlightedCatagory ? "bg-gray-100" : ""
                           }`}
                           onClick={() => handleItemSelection(result)}
                           onMouseEnter={() => setHighlightedCatagory(index)}
                         >
                           {result.c.toLowerCase()}
                         </li>
                          ))}
                        </ul>
                      )}
                  </label>
                </div>
                
                <div className="pr-2">
                  <Link href={getRedirectUrl()}>
                    <button
                      aria-label={
                        !isLocationSelected && !isCategorySelected
                          ? "Search (disabled)"
                          : "Search button"
                      }
                      className={`bg-[#11181c] hover:bg-[#000000] w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center text-white font-semibold rounded-full hover:shadow-lg transition duration-300 cursor-pointer ${
                        !isLocationSelected && !isCategorySelected
                          ? "cursor-not-allowed"
                          : ""
                      }`}
                      disabled={!isLocationSelected && !isCategorySelected}
                    >
                      {" "}
                      <svg
                        className="md:w-6 md:h-6 w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>




    </div>
  )
}

export default HeaderSearchBar;